<template>
  <div class="home padding_b" id="infinite-list">
    <div class="home_modal" :class="{'active':isModal}">
      <div class="exit_btn">
        <button @click="isModal=false">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
              <path d="M16.6668 3.33334L3.3335 16.6667" stroke="#200E32" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.6668 16.6667L3.3335 3.33334" stroke="#200E32" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
          </svg>
        </button>
      </div>
      <div class="modal_card">
        <div class="modal_title">
          {{show_title.name}}
        </div>
        <div class="modal_actions">
          <router-link :to="`/market/${show_title.id}/edit`">

            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true"
                 role="img" class="MuiBox-root css-hsw3r1 iconify iconify--eva" sx="[object Object]" width="24"
                 height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
              <path fill="#003791"
                    d="M19.4 7.34L16.66 4.6A2 2 0 0 0 14 4.53l-9 9a2 2 0 0 0-.57 1.21L4 18.91a1 1 0 0 0 .29.8A1 1 0 0 0 5 20h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71zM16 10.68L13.32 8l1.95-2L18 8.73z"></path>
            </svg>
          </router-link>
          <button @click="deleteMarket(show_title)">
            <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M12.4643 2.604L16.3754 2.60399C16.9962 2.60399 17.4995 3.10724 17.4995 3.72801C17.4995 4.34879 16.9962 4.85204 16.3754 4.85204L1.625 4.85204C1.00422 4.85204 0.500977 4.34879 0.500977 3.72802C0.500977 3.10724 1.00422 2.604 1.625 2.604L5.53604 2.604C5.96534 1.64952 6.92456 0.984924 8.03901 0.984924H9.96134C11.0758 0.984924 12.035 1.64952 12.4643 2.604Z"
                  fill="#C70505"/>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15.4391 18.2679C15.1987 19.8477 13.8403 21.0151 12.2423 21.0151H6.07134C4.47331 21.0151 3.11492 19.8477 2.87451 18.2679L1.33915 8.17836C1.16045 7.00406 2.06942 5.9463 3.25724 5.9463H15.0564C16.2442 5.9463 17.1532 7.00406 16.9745 8.17836L15.4391 18.2679ZM12.2423 18.767C12.7294 18.767 13.1434 18.4112 13.2167 17.9297L14.5669 9.05642C14.636 8.60288 14.2849 8.19434 13.8261 8.19434H4.48751C4.02874 8.19434 3.67767 8.60288 3.74669 9.05642L5.09697 17.9297C5.17024 18.4112 5.58427 18.767 6.07134 18.767H12.2423Z"
                    fill="#C70505"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="search" :class="{'active':searchs}">
      <div class="search_ch">
        <div class="input_group">
          <input type="text" id="search"  @input="searchsF()" v-model="text" ref='focusMe'>

          <svg @click="searchs=false;text='';searchsF()" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6668 1.33334L1.3335 14.6667" stroke="#200E32" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.6668 14.6667L1.3335 1.33334" stroke="#200E32" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

        </div>

      </div>
    </div>

    <div class="navbar">
      <div>
        <router-link to="/">
          <img src="../assets/images/symbol.svg" alt="">
        </router-link>
      </div>
      <div class="button_top_navs" @click="onShowText()" >
        <button>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.6642 1.99387C5.8683 1.99387 1.99951 5.92045 1.99951 10.7405C1.99951 15.5606 5.8683 19.4872 10.6642 19.4872C15.4601 19.4872 19.3288 15.5606 19.3288 10.7405C19.3288 8.42435 18.4187 6.20082 16.795 4.55966C15.1709 2.91813 12.9658 1.99387 10.6642 1.99387ZM4.08975 10.7405C4.08975 7.05371 7.04374 4.08411 10.6642 4.08411C12.4042 4.08411 14.0752 4.78266 15.3091 6.02976C16.5433 7.27724 17.2386 8.97154 17.2386 10.7405C17.2386 14.4273 14.2846 17.3969 10.6642 17.3969C7.04374 17.3969 4.08975 14.4273 4.08975 10.7405ZM19.1106 17.6386C18.7024 17.2305 18.0407 17.2305 17.6326 17.6386C17.2244 18.0468 17.2244 18.7085 17.6326 19.1166L20.2159 21.7C20.624 22.1081 21.2858 22.1081 21.6939 21.7C22.1021 21.2919 22.1021 20.6301 21.6939 20.222L19.1106 17.6386Z"
                  fill="#191923"/>
          </svg>
        </button>
        <button >

          <svg width="24" height="24" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.5841 12.4054L16.584 12.4054V12.418C16.584 13.3734 15.8941 14.0053 15.2013 14.1609L15.1404 14.1746L15.0843 14.1956L15.0835 14.1958L15.0833 14.1959L15.0829 14.1961L15.0714 14.2002C15.0591 14.2045 15.038 14.2118 15.0084 14.2216C14.9491 14.2412 14.8553 14.2709 14.728 14.3074C14.4733 14.3804 14.0844 14.4808 13.5682 14.5821C12.536 14.7846 10.9947 14.9912 9 14.9912C7.00531 14.9912 5.46395 14.7846 4.43176 14.5821C3.91559 14.4808 3.52666 14.3804 3.27199 14.3074C3.14465 14.2709 3.0509 14.2412 2.99161 14.2216C2.96196 14.2118 2.94093 14.2045 2.92863 14.2002L2.91715 14.1961L2.91674 14.1959L2.91647 14.1958L2.91573 14.1956L2.90182 14.1904L2.8854 14.1849C2.06981 13.9154 1.41595 13.3205 1.41595 12.418H1.41604L1.41587 12.4054C1.41207 12.1294 1.49791 11.6926 1.74689 11.3409C1.94751 11.0576 2.19669 10.7746 2.48895 10.4445L1.8033 9.8375L2.48895 10.4445L2.51177 10.4188C2.84912 10.0378 3.2472 9.58824 3.55489 9.09605C3.87731 8.5803 4.13983 7.95891 4.13983 7.22868C4.13983 3.82428 6.2734 1.89705 8.76115 1.89705H9.23885C11.7266 1.89705 13.8602 3.82428 13.8602 7.22868C13.8602 7.95891 14.1227 8.5803 14.4451 9.09605C14.7528 9.58823 15.1509 10.0378 15.4882 10.4188L15.511 10.4445C15.8033 10.7746 16.0525 11.0576 16.2531 11.3409C16.5021 11.6926 16.5879 12.1294 16.5841 12.4054Z"
                stroke="#191923" stroke-width="1.83151"/>
            <path
                d="M8.35753 18.1029C8.1073 18.1029 7.88327 17.9907 7.733 17.8139H10.6958C10.5455 17.9907 10.3215 18.1029 10.0712 18.1029H8.35753Z"
                stroke="#191923" stroke-width="1.83151"/>
          </svg>

        </button>
        <router-link to="/market/create">
          <svg width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0001 7.3273V14.6537" stroke="#191923" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M14.6668 10.9905H7.3335" stroke="#191923" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M15.6857 1H6.31429C3.04762 1 1 3.31208 1 6.58516V15.4148C1 18.6879 3.0381 21 6.31429 21H15.6857C18.9619 21 21 18.6879 21 15.4148V6.58516C21 3.31208 18.9619 1 15.6857 1Z"
                  stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </router-link>
      </div>
    </div>
    <div class="main_cards" >
      <div class="shop_card" v-for="(item,index) in market.data" :key="index">
        <router-link :to="`/create/shop/${item.id}`">
          <div class="shop_card_left">
            <div class="avatar_element" v-if="item.name">
              {{ item.name.charAt(0) }}
            </div>

            <div class="shop_card_text">
              <div class="shop_card_name">
                {{ item.name }}
              </div>
              <div class="shop_card_price">

                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.5" clip-path="url(#clip0_3125_3576)">
                    <path
                        d="M6.00016 7.33334L3.3335 10M3.3335 10L6.00016 12.6667M3.3335 10H10.6668C11.3741 10 12.0524 9.71905 12.5524 9.21896C13.0525 8.71886 13.3335 8.04058 13.3335 7.33334C13.3335 6.62609 13.0525 5.94782 12.5524 5.44772C12.0524 4.94762 11.3741 4.66667 10.6668 4.66667H10.0002"
                        stroke="#191923" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_3125_3576">
                      <rect width="16" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                {{ item.market_balance }} so’m
              </div>
            </div>
          </div>
        </router-link>
        <div class="shop_card_right">
          <button @click="showTitle(item)">
            <div></div>
            <div></div>
            <div></div>
          </button>

        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/Header/Footer";

export default {
  components: {Footer},
  data() {
    return {
      market: [],
      isModal:false,
      show_title:{},
      nextItem: 1,
      text:'',
      searchs:false,
    }
  },
  methods:{
    onShowText(){
      this.searchs=true
      this.$refs.focusMe.focus()
    },
    showTitle(item){
      this.show_title=item
      this.isModal=true
    },
    deleteMarket(item){
      // let isBoss = confirm("You really want to delete?");
      // if (isBoss == true) {
      //
      // }
      this.$axiosDefault.post('/market/delete-market',
          [item.id],
          {
            headers: {
              'Authorization': `Bearer  ${localStorage.getItem('_token')}`
            }
          })
          .then(() => {
            this.$axiosDefault.get('market', {
              headers: {
                'Accept-Language': 'oz',
                'Authorization': `Bearer  ${localStorage.getItem('_token')}`,

              }
            })
                .then(res => {
                  this.market = res.data
                  this.$toast("Ma'lumot o'chirildi", {
                    timeout: 2000,
                    type:"success"
                  });
                  this.isModal=false
                })
                .catch(() => {
                  localStorage.removeItem('_token')
                  this.$router.push('/login')
                })
          })
    },
    searchsF(){
      this.$axiosDefault.get(`market?name=${this.text}`, {
        headers: {
          'Accept-Language': 'oz',
          'Authorization': `Bearer  ${localStorage.getItem('_token')}`,

        }
      })
          .then(res => {

            this.market = res.data
          })

    },

  },
  mounted() {

    const listElm = document.querySelector('#infinite-list');
    listElm.addEventListener('scroll', ()=> {
      if(listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
        console.log(this.market.meta.pageCount)
        if(this.nextItem<this.market.meta.pageCount){
          this.nextItem=this.nextItem+1
          this.$axiosDefault.get(`market?page=${this.nextItem}`, {
            headers: {
              'Accept-Language': 'oz',
              'Authorization': `Bearer  ${localStorage.getItem('_token')}`,

            }
          })
              .then(res => {
                for (var v=0;v<res.data.data.length;v++){
                  this.market.data.push(res.data.data[v])
                }

              })
        }

      }
    });


    this.$axiosDefault.get('market', {
      headers: {
        'Accept-Language': 'oz',
        'Authorization': `Bearer  ${localStorage.getItem('_token')}`,

      }
    })
        .then(res => {
          this.market = res.data

        })
        .catch(() => {
          localStorage.removeItem('_token')
          this.$router.push('/login')
        })



  },

}
</script>
<style >

#infinite-list {
  overflow: auto;
  height: 100vh;
  border-radius: 5px;
}
</style>
